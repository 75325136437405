import React from "react"

import ProofOfPayment from "components/ProofOfPayment"
import PrivateRoute from "layout/PrivateRoute"

export default ({ location }) => (
  <PrivateRoute isPrivate={process.env.GATSBY_ENV_SHOW_OTP} location={location}>
    <ProofOfPayment location={location} />
  </PrivateRoute>
)
