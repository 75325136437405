import React from "react"

import Brand from "../../Elements/Brand"
import Message from "../../Elements/Message"

const ProofOfPaymentBankDetails = ({ hasOrderBeenCancelled }) => {
  return (
    <Message color={!hasOrderBeenCancelled ? "info" : "danger"}>
      {!hasOrderBeenCancelled ? (
        <div>
          <p className="is-size-6 mb-2">
            Thank you for ordering your medicines from <Brand />. Through this
            form, you can upload your proof of payment for your order. You may
            deposit your payment to:
          </p>
          <p className="is-size-6 mb-0 has-text-weight-bold">Unionbank</p>
          <p className="is-size-6 my-0">
            Account name: MG HEALTH SOLUTIONS INC
          </p>
          <p className="is-size-6 my-0">Account number: 0031 9000 0497</p>
        </div>
      ) : (
        <div>
          <p className="is-size-6 mb-2">
            Hi. Your Proof of Payment link for this order has already expired.
            Please feel free to reorder again through the website.
          </p>
          <p className="is-size-6">
            For any inquiries, please email us at order@medgrocer.com. Thank you
            and we look forward to serving you again.
          </p>
        </div>
      )}
    </Message>
  )
}

export default ProofOfPaymentBankDetails
